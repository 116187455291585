
.title-item {
    font-size: 16px;
    font-weight: 500;
    color: #060111;
    position: relative;
    padding-left: 16px;

    &:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 12px;
        background: #2821FC;
        border-radius: 3px;
        left: 0;
        top: 5px;
    }
}

.el-form {
    margin: 30px 0 0 60px;

    .el-input {
        width: 380px;
    }
}

::v-deep .el-input-number .el-input__inner {
    text-align: left;
}
