
.ad-content {
    height: 100%;
    display: flex;
    flex-direction: column;

    .el-scrollbar {
        margin: 20px;
        height: calc(100% - 40px);
    }

    ::v-deep .el-scrollbar__wrap {
        height: 100%;
        overflow-x: hidden;

        .el-scrollbar__view {
            height: 100%;
            display: flex;
            flex-direction: column;
        }
    }
}

.steps-wrapper {
    margin: 20px 0 10px;
}

.data-form {
    flex: 1;
    height: 1%;
}

.footer-content {
    text-align: center;
    margin: 20px 0;
}
