
.setting-box {
    &:nth-of-type(2) .column-item {
        margin-left: 5px;

        &:nth-of-type(6n + 1) {
            margin-left: 0;
        }
    }
}

.title-item {
    font-size: 16px;
    font-weight: 500;
    color: #060111;
    position: relative;
    padding-left: 16px;
    padding-bottom: 10px;
    border-bottom: 1px solid #EEEEEE;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        width: 6px;
        height: 12px;
        background: #2821FC;
        border-radius: 3px;
        left: 0;
        top: 5px;
    }
}

.item-column-content {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    .column-item {
        margin-left: 5px;

        &:nth-of-type(6n + 1) {
            margin-left: 70px;
        }

        .odd-item {
            margin-left: 0;
        }

        .odd-content {
            margin-top: 20px;
        }
    }

    .row-item {
        width: 100%;

        .odd-content {
            display: flex;
            flex-wrap: wrap;

            .main-content {
                width: unset;
                margin-top: 20px;

                &:nth-of-type(6n + 1) {
                    margin-left: 100px;
                }
            }
        }

        &:first-child {
            .odd-content {
                margin-top: 0;

                .main-content {
                    margin-top: 0;
                }
            }
        }

        .odd-item {
            margin-left: 10px;

            .el-input {
                margin-left: 0;
                width: 210px;
            }
        }
    }
}

.odd-content {
    display: flex;
    width: 100%;
    margin-top: 10px;

    .main-content {
        display: flex;
        width: 100%;
        align-items: center;
        flex-direction: column;
    }

    .odd-item {
        display: flex;
        align-items: center;
        margin-left: 40px;

        .el-input {
            margin-left: 0;
            width: 210px;
        }
    }

    .item-title {
        width: 100%;
        text-align: center;
        margin: 0 20px 10px 0;
        color: #1B162A;
    }
}

.item-content-title {
    color: #1B162A;
    width: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    margin-bottom: 10px;
}

.input-text {
    margin: 0 10px;
}

.divide {
    width: 15px;
    height: 2px;
    background: #D2D2D2;
    margin: 0 4px;
}

.el-input {
    margin-left: 40px;

    &:nth-of-type(2) {
        margin-left: 0;
    }
}

.show-more {
    width: 100%;
    height: 30px;
    background: #F8F8FD;
    margin-bottom: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #6762FB;
    cursor: pointer;

    i {
        margin-left: 10px;
        color: #6762FB;
    }
}
